
import './App.css';
import React from 'react';
import uberLogo from './assets/uberLogo.png'
import skipthedishesLogo from './assets/skipthedishesLogo.png'
import ritualLogo from './assets/ritualLogo.png'
import mg from './assets/mgOutside.png'


function App() {
  return (
    
    <div className="Container">

      <h1>Market Grill</h1>
      <h3>878 Weber St N, Waterloo, ON N2J 4A9</h3>
      <h3>St. Jacobs Farmer's Market - North Side (facing King St.)</h3>
      <br></br>


        
      <div className='hoursContainer'>
     
        <img src={mg} className = "backg" />
        
        <div style={{width:"50vh"}}>
          <p>Hours</p>
          <p>We are open Tuesday, Thurday and Saturday year round</p>
          <p>Tuesday 7:30 am - 3:00pm</p>
          <p>Thursday 7:30 am - 3:00pm</p>
          <p>Saturday 7:00 am - 3:30pm</p>
        </div>

      </div>

      <br></br>
     
      <p>Order Online Below!</p>
      
      <a href="https://www.ubereats.com/ca/store/market-grill/OpgzWiABS4yaY_xhVBDMAw" target="_blank">
        <img src={uberLogo} className = "logo" width = "200" height = "200"/>
      </a>
      <a href="https://www.skipthedishes.com/the-market-grill" target="_blank">
        <img src={skipthedishesLogo} className = "logo" width = "200" height = "200"/>
         </a>      
         
      <a href="https://ritual.co/order/market-grill-st-jacobs-farmers-market-waterloo/4ac3?r=MARKETGRILL" target="_blank">
        <img src={ritualLogo} className = "logo" width = "200" height = "200"/>
      </a>
    
      <br></br>

        <h5>Copyright 2022 Market Grill - Website made in-house</h5>

    </div>

  );
}

export default App;
